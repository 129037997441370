import React, { useCallback } from 'react';

import { GADataType, I18nText } from '../../../../../types';

import { IconsEnum } from '../../../../../assets/icons/types';

import { useIndexPageFiltersPopoverState } from '../../../hooks/useIndexPageFiltersPopoverState';

import { TextFilter } from '../../../../../helpers/filters/TextFilter';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { isDesktop } from '../../../../../utils/isDesktop';

interface IndexTableMainFilterProps {
  fieldName: string;
  value: string;
  i18nPlaceholder: I18nText;
  onSearch: (value: string) => void;
  scope: string;
  withoutPopoverButton?: boolean;
}

function IndexTableMainFilter({
  fieldName,
  dataGa,
  value,
  i18nPlaceholder,
  onSearch,
  scope,
  withoutPopoverButton
}: IndexTableMainFilterProps & GADataType) {
  const {
    toggleActiveFiltersPopover,
    openSavedFiltersPopover,
    closeSavedFiltersPopover
  } = useIndexPageFiltersPopoverState();

  const handleTextFilterChange = useCallback<
    (textFilterValue: { [fieldName: string]: string }) => void
  >(
    (textFilterValue: { [fieldName: string]: string }) => {
      if (!isDesktop()) {
        closeSavedFiltersPopover();
      }
      onSearch(textFilterValue[fieldName]);
    },
    [fieldName, onSearch]
  );

  return (
    <div className="flex items-center w-full max-w-lg">
      <div className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl dark:focus-within:bg-gray-700 focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 h-10 relative rounded-lg text-gray-400 border border-transparent w-full">
        <TextFilter
          dataGa={`${scope}-text-filter-input`}
          id={`${scope}-text-filter-input`}
          inputClassName="bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full pl-12 placeholder-gray-400 pr-12 py-2 text-gray-900 w-full"
          name={fieldName}
          value={value}
          onChange={handleTextFilterChange}
          onFocus={openSavedFiltersPopover}
          i18nPlaceholder={i18nPlaceholder}
        />
        {withoutPopoverButton ? null : (
          <div className="absolute flex inset-y-0 items-center leading-5 pr-1 right-0 text-sm">
            <PureIconButtonHelper
              className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              icon={IconsEnum.ARROW_DOWN_TRIANGLE_SOLID}
              id={`${scope}-filters-popover-button`}
              dataGa={`${scope}-filters-popover-button`}
              onClick={toggleActiveFiltersPopover}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default IndexTableMainFilter;
